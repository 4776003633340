.layout-header {
  background-color: white !important;
  box-shadow: 0 4px 10px rgba(black, 0.075);
  position: relative;
  z-index: 99;
}

.layout-sidebar {
  overflow: auto !important;
  height: 100vh !important;
  position: fixed !important;
  left: 0 !important;
  border-right: 3px solid #70d44b;
  background-color: white !important;
  box-shadow: 4px 0 6px rgba(black, 0.075);
}

.layout-content, .ant-layout {
  background-color: transparent !important;
}

.layout-content {
  padding: 24px;
}